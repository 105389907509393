import * as React from "react"

import { useStaticQuery, PageProps, Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Features from "../../components/features"

type DataProps = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string;
    }
  }
}

const BlogPage: React.FC<PageProps<DataProps>> = (props) => (
  <Layout {...props}>
    <Seo title="Blog - What is hypnotherapy" />

      <article className="prose lg:prose-xl py-24 mx-auto">
        <h1 className="">What is hypnotherapy</h1>

        <p className="lead">Hypnosis is a  natural state of focussed attention many of us experience every day. Whenever you are in a state of relaxed intense concentration, not distracted by outside influences, you are in a light state of hypnosis.</p>
        <blockquote><p>All hypnosis is self - hypnosis in which you control the process: the therapist acts as a guide or facilitator.</p></blockquote>
        <p>One goal of hypnosis is to access the subconscious mind. This is the part of the mind that lies beneath the bombardment of outside mental processes and stimuli. In the subconscious mental processes occur without our conscious awareness. We experience moments of intuition, wisdom and creative ideas when subconscious processes flash into our conscious awareness. The subconscious is not limited by our imposed  boundaries. It can remember anything from any time.</p>
        <p>The subconscious can transcend  the ordinary to touch upon a wisdom far beyond our everyday capabilities. Hypnotherapy accesses the wisdom of the subconscious in a focussed way in order to achieve growth and healing. We are in hypnosis whenever the relationship between the conscious and subconscious mind is altered so that the subconscious plays a more dominant role. There are a variety of hypnotic strategies available to guide into different levels of hypnosis from light to deep.</p>
        <p>All hypnosis is self - hypnosis in which you control the process: the therapist acts as a guide or facilitator.</p>
        <p>Your conscious mind is <strong>always</strong> in control and is <strong>aware</strong> of everything that happens during hypnosis. Hypnosis is <strong>not</strong> a form of mind control.</p>
      </article>

    <Features elements={['zoom', 'why']} />
  </Layout>
)

export default BlogPage

export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
      siteMetadata {
        title
      }
    }
  }
`
